.logo {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.logoBg {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}

.contestsTable {
  table-layout: fixed;
  width: 100%;

  & th {
    text-align: left;

    &:nth-child(1) {
      width: 1rem;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 45%;
    }
    &:nth-child(5) {
      width: 12rem;
      text-align: center;
    }
  }
}

.finishedContestsTable {
  table-layout: fixed;
  width: 100%;

  & th {
    text-align: left;

    &:nth-child(1) {
      width: 1rem;
    }
    &:nth-child(2) {
      width: 30%;
    }
    &:nth-child(3) {
      width: 20%;
    }
    &:nth-child(4) {
      width: 40%;
    }
    &:nth-child(5) {
      width: 10rem;
      text-align: center;
    }
  }
}

.mono {
  font-family: monospace;
}

.warning {
  color: #ffcc00;
}

.warningBox {
  border: 1px solid #ffcc00;
  padding: 1rem;
  background-color: transparentize($color: #ffcc00, $amount: 0.9);
  color: #ffcc00;
}
